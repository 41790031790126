<template>
  <div class="content-wrapper">
    <section class="content">
      <section style="margin-bottom: 1%">
        <a-button type="primary" @click="createPaymentRequest">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新增付款申请单') }}
        </a-button>
      </section>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.requestSn"
            :placeholder="$t('请输入申请单号')"
          />
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            id="requestTime"
            :needhms="false"
            @get_date="chooseRequestTime"
            :placeholder="$t(`请款时间`)"
            :start-time="formData.applyTimeStart"
            :end-time="formData.applyTimeEnd"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :placeholder="$t('单据类型')"
            :list="payment_request_type"
            :code.sync="formData.orderType"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" class="btnBgc">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>

      <div class="table-content">
        <CommonTable
          ref="CommonTableRef"
          :columns.sync="paymentRequestColumns"
          :data-source="paymentRequestData"
          :statusList="payment_request_status"
          rowKey="id"
          :scroll="{ x: 1200 }"
          :loading="loading"
          :total="total"
          :showSelect="true"
          :selectedRowKeys.sync="selectedRowKeys"
          :selectedRows.sync="selectedRows"
          @select="onSelectChange"
          @search="queryPaymentRequestList"
        >
          <template v-slot:header>
            <OperateBtn
              api="checkOrder"
              :text="$t('审核')"
              :params="{
                docType: docType,
                idList: selectedRowKeys,
              }"
              :disabled="selectedRows.length === 0"
              @success="searchHandle"
            />
            <PushBtn
              class="ml10"
              :docType="docType"
              :selectedRows="selectedRows"
              :disabled="selectedRows.length === 0"
            />
          </template>
          <template v-slot:paymentRequestSn="{ text, record }">
            <span class="text-link" @click="viewDetail(record)">{{ text }}</span>
          </template>
          <template v-slot:payableAmount="{ text }">
            <span v-if="text">¥ {{ text | toThousands }}</span>
          </template>
        </CommonTable>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonSelect from '@component/commonSelect'
import CommonTable from '@component/commonTable'
import PushBtn from '@component/pushBtn'
import DateRangePicker from '@component/dateRangePicker'
import { spaceToComma, convertKeysToCamelCase } from '@/common'

export default {
  name: 'paymentRequestList',
  components: { CommonSelect, CommonTable, DateRangePicker, PushBtn },
  data() {
    return {
      docType: 'PaymentRequest',
      formData: {
        requestSn: '',
        applyTimeStart: undefined,
        applyTimeEnd: undefined,
        orderType: undefined,
      },
      total: 0,
      loading: false,
      paymentRequestData: [],
      selectedRowKeys: [],
      selectedRows: [],

      paymentRequestColumns: [
        {
          title: this.$t('申请单号'),
          dataIndex: 'paymentRequestSn',
          width: 200,
          scopedSlots: { customRender: 'paymentRequestSn' },
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'orderType',
          width: 150,
          customRender: (text) => this.paymentRequestTypeMapping[text],
        },
        {
          title: this.$t('单据状态'),
          dataIndex: 'status',
          customRender: (text) => this.paymentRequestStatusMapping[text],
          width: 150,
        },
        {
          title: this.$t('往来单位类型'),
          width: 150,
          dataIndex: 'payeeType',
          customRender: (text) => this.$store.getters.contactTypeMapping[text],
        },
        {
          title: this.$t('往来单位名称'),
          width: 150,
          dataIndex: 'payeeName',
        },
        {
          title: this.$t('请款日期'),
          dataIndex: 'applyTime',
          width: 200,
        },
        {
          title: this.$t('请款事由'),
          dataIndex: 'applyReason',
          width: 150,
        },
        {
          title: this.$t('结算货币'),
          dataIndex: 'settlementCurrency',
          width: 150,
          customRender: (text) => this.currencyListMapping[text],
        },
        {
          title: this.$t('申请金额'),
          dataIndex: 'payableAmount',
          scopedSlots: { customRender: 'payableAmount' },
          width: 150,
        },
        {
          title: this.$t('付款货币'),
          dataIndex: 'paymentCurrency',
          width: 150,
          customRender: (text) => this.currencyListMapping[text],
        },
        {
          title: this.$t('备注'),
          dataIndex: 'remark',
          width: 150,
        },
        {
          title: this.$t('申请人'),
          dataIndex: 'createdUser',
          width: 150,
        },
      ],
    }
  },
  activated() {
    this.searchHandle()
  },
  computed: {
    ...mapState(['payment_request_status', 'payment_request_type']),
    ...mapGetters([
      'paymentRequestStatusMapping',
      'paymentRequestTypeMapping',
      'currencyListMapping',
    ]),
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle(1)
    },
    searchHandle(pageNo) {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    createPaymentRequest() {
      this.$router.push({ name: 'paymentRequestDetail' })
    },
    chooseRequestTime(date) {
      this.formData.applyTimeStart = date.start_time
      this.formData.applyTimeEnd = date.end_time
    },
    async queryPaymentRequestList(params) {
      const data = {
        ...this.formData,
        ...params,
      }
      let requestSn = spaceToComma(this.formData.requestSn)
      if (requestSn.includes(',')) {
        data.requestSnList = requestSn.split(',')
      } else if (requestSn) {
        data.requestSn = requestSn
      }
      this.loading = true
      const res = await http({
        type: 'post',
        url: api.getPaymentRequestList,
        data,
      })
      this.paymentRequestData = convertKeysToCamelCase(res.result.list) || []
      this.total = res.result.total
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'paymentRequestDetail/' + record.paymentRequestSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.paymentRequestSn,
        },
        route: `/payableManage/paymentRequestDetail?id=${record.id}&sn=${record.paymentRequestSn}`,
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>

<style lang="scss" scoped>
.btnBgc {
  background-color: #0088ff;
  color: #ffffff;
}
</style>
